import React, { useRef } from 'react';
import { Table, Spin } from 'antd';

const CustomTable = ({
  data,
  columns,
  loading = false,
  onRowClick,
  pageSize = 10,
  emptyMessage = 'No data available',
  onSearch,
  searchable = false,
  rowSelection = null,
  infiniteScroll = false,
  onLoadMore,
}) => {
  const tableContainerRef = useRef(null);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight && !loading && onLoadMore) {
      onLoadMore();
    }
  };

  const tableOptions = {
    rowKey: 'key', // Ensure 'key' is used as the unique identifier for rows
    pagination: infiniteScroll
      ? false // Disable pagination for infinite scroll
      : {
          pageSize,
          showSizeChanger: false,
        },
    loading,
    onRow: (record) => ({
      onClick: () => onRowClick && onRowClick(record),
    }),
    locale: {
      emptyText: loading ? <Spin tip="Loading data..." /> : emptyMessage,
    },
    rowSelection, 
  };

  return (
    <div
      ref={tableContainerRef}
      onScroll={infiniteScroll ? handleScroll : null}
      style={infiniteScroll ? { maxHeight: '600px', overflowY: 'auto', width: '100%' } : {}}
    >
      <Table dataSource={data} columns={columns} {...tableOptions} />
    </div>
  );
};

export default CustomTable;
